@import '../../../../../../styles/mixins';

.root {
  position: relative;
  overflow: hidden;
}

.header {
  @include use-spacings(padding-bottom, s);
}

.header-pattern {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  svg {
    width: initial;
    height: initial;
  }
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;

  @include use-spacings(margin-top, m);
}

.content-wrap {
  @include use-spacings(padding-top, l);
}

.content {
  display: flex;
  flex-direction: column;
}

.content-title {
  width: 100%;
}

.content-links {
  @include use-spacings(margin-top, l);
}

.link {
  display: block;

  &:not(:first-child) {
    @include use-spacings(margin-top, m);
  }

  @include main-link();
}

.services {
  display: flex;
  flex-direction: column;

  @include use-spacings(margin-top, l);
}

.logo {
  max-width: 180px;
}

.service:not(:last-child) {
  @include use-spacings(margin-bottom, 'l');
}

.service-title {
  @include use-spacings(margin-top, 's');
  @include small-light-text();
}

@media (min-width: 400px) {
  .header-pattern {
    max-height: 200px;
  }
}

@media (min-width: $extra-mobile-size) {
  .header-pattern {
    max-height: 190px;
  }
}

@media (min-width: $mobile-size) {
  .header {
    @include use-spacings(padding-bottom, l);
  }

  .header-pattern {
    max-height: initial;
    overflow: initial;
  }

  .content-wrap {
    @include use-spacings(margin-top, l);
    @include flex-columns();
  }

  .services {
    @include flex-columns(3);
  }

  .content-links {
    margin-top: 0;
  }
}

@media (min-width: $tablet-size) {
  .service:not(:last-child) {
    margin: 0 $column-gap 0 0;
  }
}
