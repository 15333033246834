@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.wrapper {
  position: relative;

  @include use-spacings(padding-top, 56px);
  @include use-spacings(padding-bottom, 56px);

  @media (min-width: $tablet-size) {
    @include use-spacings(padding-top, l);
    @include use-spacings(padding-bottom, l);
  }

  @media (min-width: $desktop-size) {
    @include use-spacings(padding-top, 56px);
    @include use-spacings(padding-bottom, 56px);
  }
}

.app {
  position: relative;
  z-index: 10;
  flex-wrap: wrap;
  max-width: 592px;
  background: color(mainwhite);
  border-radius: 16px;
  box-shadow: 0 18px 65px rgba(0, 0, 0, 0.07), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);

  @include use-spacings(margin, auto);
  @include use-spacings(padding, m);
  @include big-body-text();

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-right, 100%);

    min-width: 480px;
    margin-left: 0;
  }

  @media (min-width: $desktop-size) {
    min-width: 592px;
  }
}

.img {
  position: absolute;
  bottom: 0;
  left: 60%;
  z-index: 1;
  display: none;

  @media (min-width: $tablet-size) {
    display: flex;
  }

  @media (min-width: $desktop-size) {
    left: 55%;
  }
}

.header {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  @include use-spacings(margin-bottom, s);

  h4 {
    width: 100%;

    @media (min-width: $extra-mobile-size) {
      width: initial;
    }
  }

  svg {
    width: 64px;
    height: 64px;
  }

  @media (min-width: $extra-mobile-size) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
