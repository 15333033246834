@import '../../../../../../../../styles/variables';
@import '../../../../../../../../styles/mixins';

.info {
  @include use-spacings(margin-bottom, m);
}

.h2 {
  @include use-spacings(margin-bottom, m);
}

.step {
  @include use-spacings(padding-right, m);
}

.hint {
  color: color(black50);
}

.content {
  display: flex;
}

.desktop-frame {
  display: none;
}

.row {
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  @include use-spacings(margin-bottom, m);

  &:last-child {
    margin-bottom: 0;
  }
}

.indent-l {
  @include use-spacings(margin-bottom, l);
}

.indent-m {
  @include use-spacings(margin-bottom, m);
}

.indent-s {
  @include use-spacings(margin-bottom, s);
}

.indent-xs {
  @include use-spacings(margin-bottom, xs);
}

.point-wrap {
  align-self: flex-start;
}

.point {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #c2e9ff;
  border-radius: 100%;

  @include use-spacings(margin-right, s);
}

.frame,
.desktop-frame {
  background-color: #f8d374;
  border-radius: $base-border-radius;

  @include use-spacings(padding, m);
}

.times {
  @include use-spacings(margin-bottom, s);
}

@media (min-width: $tablet-size) {
  .desktop-frame {
    display: flex;
    flex-direction: column;
    align-self: baseline;

    @include use-spacings(margin-left, m);
  }

  .frame {
    display: none;
  }
}
