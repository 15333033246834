@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.section {
  @include use-spacings(margin-bottom, xl);
}

.controller {
  display: flex;
  flex-direction: column;
  height: 215px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  overflow: hidden;
  border: 1px solid color(gray4);
  border-radius: 16px;

  img {
    z-index: -1;
    position: absolute;
    object-fit: fill;
    max-height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @include use-spacings('padding', 's');

  @media (min-width: $mobile-size) {
    height: 315px;

    @include use-spacings('padding', 'm');
  }
}

.title {
  @media (min-width: $mobile-size) {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  }
}

.desc {
  max-width: 200px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;

  @media (min-width: $extra-mobile-size) {
    max-width: none;
  }

  @media (min-width: $mobile-size) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;
  margin-top: auto;
  color: color(black50);

  @media (min-width: $mobile-size) {
    max-width: 230px;
  }

  li {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    background-color: #f2f2f2;
    border-radius: 32px;

    @media (min-width: $mobile-size) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.loan {
  position: relative;

  &:not(.active):active,
  &:hover {
    box-shadow: 0 10px 10px rgba(0, 161, 220, 0.3), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);
  }

  &.active {
    border: none;
  }
}

.economy {
  position: relative;

  &:not(.active):active,
  &:hover {
    box-shadow: 0 10px 10px rgba(98, 230, 172, 0.3), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);
  }

  &.active {
    border: none;
  }
}

.technology {
  position: relative;

  &:not(.active):active,
  &:hover {
    box-shadow: 0 10px 10px rgba(140, 131, 251, 0.3), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);
  }

  &.active {
    border: none;
  }
}

.slider {
  // stylelint-disable-next-line
  :global(.slick-track) {
    display: flex;
    flex-wrap: nowrap;
  }

  // stylelint-disable-next-line
  :global(.slick-slide) {
    @include use-spacings(margin-right, 8px);
    @include use-spacings(margin-left, 8px);

    @media (min-width: $mobile-size) {
      @include use-spacings(margin-right, 16px);
      @include use-spacings(margin-left, 16px);
    }
  }

  // stylelint-disable-next-line
  :global(.slick-list) {
    @include use-spacings(margin-right, -8px);
    @include use-spacings(margin-left, -8px);

    @media (min-width: $mobile-size) {
      @include use-spacings(margin-right, -16px);
      @include use-spacings(margin-left, -16px);
    }

    padding: 0 5% 15px 0 !important;
  }
}
