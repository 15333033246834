@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.wrapper {
  @include use-spacings(padding-top, l);
  @include use-spacings(padding-bottom, m);

  background: color(graylight);
}

.content {
  color: color(gray4);

  @include extra-small-text();

  p {
    @include use-spacings(margin-bottom, l);
  }
}
