@import 'src/styles/mixins';
@import 'src/styles/functions';

.section {
  @include use-spacings(margin-bottom, xl);
}

.disclaimer {
  @media screen and (min-width: $tablet-size) {
    @include flex-columns(2, row, 32px);

    justify-content: space-between;
  }

  > div {
    @include use-spacings(margin-bottom, xs);

    @media screen and (min-width: $tablet-size) {
      @include use-spacings(margin-bottom, 0);
    }
  }

  p {
    color: color(black50);

    @include small-text();
  }
}

.details {
  @include link-style();
}

.images {
  display: flex;

  @include use-spacings(gap, 64px);
  @include use-spacings(margin-bottom, s);

  svg {
    width: 100%;
    height: 36px;

    @media (min-width: $desktop-size) {
      height: 40px;
    }
  }
}
