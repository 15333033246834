@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  overflow: hidden;
  border-radius: $base-border-radius;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video[poster] {
  object-fit: cover;
  min-width: 100%;
}

.play-icon {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  width: 56px;
  height: 56px;
  cursor: pointer;
}

@media (min-width: $mobile-size) {
  .play-icon {
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
  }
}
