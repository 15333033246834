@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  display: grid;
  grid-template-areas:
    "title"
    "terms"
    "button";
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  gap: 0 0;

  @media (min-width: $tablet-size) {
    grid-template-areas:
      "title terms"
      "button terms";
    grid-template-rows: 200px 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $desktop-size) {
    grid-template-rows: auto;
    grid-template-columns: 350px 1fr;
    gap: 40px 52px;
  }
}

.title {
  grid-area: title;

  @include use-spacings(margin-bottom, l);

  @media (min-width: $tablet-size) {
    align-self: flex-end;
    max-width: 270px;
  }

  @media (min-width: $desktop-size) {
    @include use-spacings(margin-bottom, 0);
  }
}

.terms {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: terms;
  gap: 24px;

  @include use-spacings(margin-bottom, 72px);

  li {
    display: flex;
    align-items: center;
    width: 100%;

    svg {
      width: 40px;
      height: 40px;

      @include use-spacings(margin-right, 16px);
    }

    span {
      font-size: 20px;
      line-height: 28px;

      @media (min-width: $desktop-size) {
        max-width: 285px;
      }
    }

    @media (min-width: $mobile-size) {
      max-width: 50%;

      @include use-spacings(margin-bottom, m);
    }

    @media (min-width: $tablet-size) {
      max-width: 100%;
    }

    @media (min-width: $desktop-size) {
      max-width: 50%;

      @include use-spacings(margin-bottom, 0);
    }
  }

  li:last-child {
    @media (min-width: $tablet-size) {
      @include use-spacings(margin-bottom, 0);
    }
  }

  @media (min-width: $mobile-size) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;

    @include use-spacings(margin-bottom, 48px);
  }

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-bottom, 0);
  }

  @media (min-width: $desktop-size) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }
}

.button {
  grid-area: button;
  height: 64px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  @media (min-width: $mobile-size) {
    width: 360px;
    margin: 0 auto;
  }

  @media (min-width: $tablet-size) {
    max-width: 270px;
    margin: 0;
  }
}
