@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-areas:
    "title"
    "products"
    "button";
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  gap: 0 0;

  @media (min-width: $desktop-size) {
    grid-template-areas:
      "title products"
      "title button";
    grid-template-rows: auto;
    grid-template-columns: 325px 1fr;
    gap: 0 74px;
  }
}

.title {
  grid-area: title;

  @include use-spacings(margin-bottom, l);
}

.products {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: products;
  gap: 20px;

  @include use-spacings(margin-bottom, l);

  @media (min-width: $mobile-size) {
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
  }
}

.button {
  grid-area: button;
  height: 75px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 160px;
  overflow: hidden;
  cursor: pointer;
  background-color: color(mainwhite);
  border-radius: 24px;
  box-shadow: $card-shadow;

  @include use-spacings(padding, m);

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  @media (min-width: $mobile-size) {
    width: calc(50% - 16px);
  }
}

.name {
  z-index: 10;
  max-width: 70%;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.image {
  position: absolute;
  inset: unset !important;
  height: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  max-width: 166px;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}
