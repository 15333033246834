@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/functions';

.title {
  text-align: center;

  @include use-spacings(margin-bottom, l);
}

.calculator-input-label {
  display: block;
  color: color(black50);

  @include use-spacings(margin-bottom, s);
}

.calculator-inputs-wrap {
  width: 100%;
}

.calculator-input {
  @include use-spacings(margin-top, l);
}

body .input-range-result {
  background: color(bluealice);
}

body .track {
  background: color(pacificblue);
}

body .active-track {
  background: color(main);
}

.wrapper,
.results-wrapper {
  @include use-spacings(padding-left, l);
  @include use-spacings(padding-right, l);
}

.mobile-button {
  display: block;
  margin-right: auto;
  margin-left: auto;

  @include use-spacings(margin-top, l);
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: color(pacificblue);
  border-radius: $base-border-radius;

  @include use-spacings(margin-top, l);
  @include use-spacings(padding, m);
}

.results-item {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:last-child {
    @include use-spacings(margin-top, m);
  }
}

.results-label {
  display: block;
  color: color(black50);

  @include use-spacings(margin-bottom, xs);
}

@media (min-width: $extra-mobile-size) {
  .results {
    flex-direction: row;
  }

  .results-item {
    &:last-child {
      margin-top: 0;
    }
  }

  .results-label {
    @include use-spacings(margin-bottom, s);
  }
}

@media (min-width: $tablet-size) {
  .calculator-inputs-wrap {
    @include flex-columns();
  }

  .wrapper,
  .results-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .wrapper {
    border-radius: $base-border-radius;
    box-shadow: $card-shadow;

    @include use-spacings(padding, l);
  }

  .results {
    @include use-spacings(padding-left, l);
    @include use-spacings(padding-right, l);
  }
}
