@import '../../../../../../styles/mixins';

.title {
  text-align: center;

  @include use-spacings(margin-bottom, l);
}

.form-wrapper {
  @include use-spacings(margin-top, l);
}

.hint {
  @include use-spacings(margin-top, l);
  @include use-spacings(margin-bottom, l);
}

.controls {
  display: flex;
  flex-direction: column;
}

.back-btn {
  @include use-spacings(margin-top, l);
}

@media (min-width: $mobile-size) {
  .controls {
    @include flex-columns($flex-direction: row-reverse);
  }

  .back-btn {
    margin-top: 0;
  }
}
