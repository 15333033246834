@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include use-spacings(margin-top, xl);
  @include use-spacings(margin-bottom, xl);
}

.cards {
  @include use-spacings(margin-top, l);
}

.subtitle {
  @include use-spacings(margin-top, s);
}

.cardStyles {
  border-radius: $base-border-radius;
  box-shadow: $card-shadow;

  &:not(:first-child) {
    @include use-spacings(margin-top, m);
  }

  @include use-spacings(padding, l);
}

.card-header {
  display: flex;
}

.avatar {
  width: 64px;
  min-width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 100%;

  @include use-spacings(margin-right, s);
}

.about-person {
  padding: 7.5px 0;
}

.position {
  min-height: 16px;

  @include small-light-text();
}

.card-content {
  @include use-spacings(margin-top, m);
}

.show-more {
  cursor: pointer;

  @include small-text();
  @include use-spacings(margin-top, m);
}

.actions {
  width: 100%;
}

.button {
  width: 100%;

  @include use-spacings(margin-top, l);
}

@media (min-width: $mobile-size) {
  .button {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: $tablet-size) {
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - #{$column-gap} / 2);
    margin-top: $column-gap;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  .card-header {
    min-height: 76px;
  }

  .show-more {
    text-align: center;
  }

  .actions {
    @include flex-columns(3);

    .button {
      width: 100%;
    }
  }

  .about-person {
    padding: 0;
  }

  .twoColsCard {
    display: flex;
    flex-direction: column;
    width: calc(100% / 2 - #{$column-gap} / 2);
    margin-top: $column-gap;

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }

    .actions {
      @include flex-columns(2);
    }
  }
}

.reviewLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;

  @include use-spacings(margin-top, l);

  a {
    display: flex;
    align-items: center;
  }

  span {
    @include h5-text();
  }

  svg {
    width: min-content;
    height: 32px;
  }

  .image {
    display: flex;
    width: 127px;
    max-width: 127px;
  }
}
