@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.root {
  position: relative;
  overflow: hidden;
}

.header {
  position: relative;

  @include use-spacings(padding, m);

  @media screen and (min-width: $extra-mobile-size) {
    @include use-spacings(padding, l);
    @include use-negative-spacings(margin, l);
  }
}

.back {
  @include use-spacings(margin-top, m);
}

.pattern {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #edf8ff;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;

  @media screen and (min-width: $extra-mobile-size) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.list-item {
  display: flex;
  align-items: center;

  @include use-spacings(margin-bottom, 20px);

  img {
    width: 120px;
    max-width: unset;
    height: 88px;
    // overflow: hidden;
  }
}

.image-container {
  @include use-spacings(margin-right, l);
}

.subtitle {
  @include use-spacings(margin-top, m);
}

.content-wrap {
  h3 {
    @include use-spacings(margin-bottom, s);
  }

  @include use-spacings(padding-top, l);
}

.content {
  display: flex;
  flex-direction: column;
}

.content-title {
  width: 100%;
}

.content-links {
  @include use-spacings(margin-top, l);
  @include use-spacings(margin-bottom, s);
}

.link {
  display: block;

  &:hover {
    @include main-link();
  }
}

.main-link {
  @include main-link();
}

.services {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include use-spacings(margin-bottom, s);

  iframe {
    width: 100%;
    height: 253px;
    border-radius: 8px;

    @include use-spacings(margin-bottom, l);

    &:last-child {
      @include use-spacings(margin-bottom, 0);
    }

    @media screen and (min-width: $extra-mobile-size) {
      @include use-spacings(margin-bottom, 0);
    }
  }

  @include use-spacings(margin-top, l);
}

.logo {
  max-width: 180px;
}

.service:not(:last-child) {
  @include use-spacings(margin-bottom, 'l');
}

.service-title {
  @include use-spacings(margin-top, 's');
  @include small-light-text();
}

@media (min-width: 400px) {
  .header-pattern {
    max-height: 200px;
  }
}

@media (min-width: $extra-mobile-size) {
  .header-pattern {
    max-height: 190px;
  }
}

@media (min-width: $mobile-size) {
  .content-wrap {
    @include use-spacings(margin-top, l);
  }

  .services {
    @include flex-columns(2);
  }

  .content-links {
    margin-top: 0;
  }
}

@media (min-width: $tablet-size) {
  .service:not(:last-child) {
    margin: 0 $column-gap 0 0;
  }
}
