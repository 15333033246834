@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.root {
  width: 100%;

  @include use-spacings(padding-left, s);
  @include use-spacings(padding-right, s);
  @include use-spacings(padding-top, xl);
  @include use-spacings(padding-bottom, xl);
}

@media (min-width: $mobile-size) {
  .root {
    max-width: 770px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: $mobile-size) {
  .root {
    min-width: 770px;
  }
}
