@import 'src/styles/mixins';
@import 'src/styles/variables';

.hint {
  @include use-spacings(margin-top, xs);
}

.submit-button-wrap {
  @include use-spacings(margin-top, l);
}

.button {
  width: 100%;
}

.form-controls {
  width: 100%;

  @include use-spacings(margin-top, l);
}

.privacy {
  flex: 0;
  min-width: 250px;
  color: color(black50);
  text-align: left;

  @include small-text();
  @include use-spacings(margin-top, l);
}

.modalContent {
  @include use-spacings(margin-top, l);
}

.submitted-button {
  pointer-events: none;
  background: color(greenbright);

  @include small-text();
}

@media (min-width: $mobile-size) {
  .form-controls {
    display: flex;
    justify-content: space-between;
  }

  .privacy {
    margin-top: 0;
  }

  .submit-button-wrap {
    margin-top: 0;
  }
}
