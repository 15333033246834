@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.section {
  @include use-spacings(margin-bottom, xl);
}

.sectionTitle {
  @include h3-text();
  @include use-spacings(margin-bottom, l);
}

.backgroundBlueAlice {
  background: color(bluealice);
}

.backgroundImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.sectionComfortTerms {
  position: relative;

  @include use-spacings(padding-top, 48px);
  @include use-spacings(padding-bottom, 48px);

  color: color(mainwhite);
  background-color: color('blueDark');
  // background-image: url(/images/comfortTermsMaskMob.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;

  @media (min-width: $mobile-size) {
    // background-image: url(/images/comfortTermsMaskTablet.png);

    @include use-spacings(padding-top, 64px);
    @include use-spacings(padding-bottom, 76px);
  }

  @media (min-width: $tablet-size) {
    @include use-spacings(padding-top, 56px);
    @include use-spacings(padding-bottom, 56px);
  }

  @media (min-width: $desktop-size) {
    // background-image: url(/images/comfortTermsMaskLargeDesctop.png);

    @include use-spacings(padding-top, xl);
    @include use-spacings(padding-bottom, xl);
  }
}

.sectionProductsLandPages {
  @include use-spacings(padding-top, 48px);
  @include use-spacings(padding-bottom, 60px);
  @include use-spacings(margin-bottom, 56px);

  position: relative;
  overflow: hidden;
  background-color: color('bluealice');

  > svg {
    position: absolute;
    top: -120px;
    left: -80px;
    width: 544px;
    height: 544px;
  }
}

.sectionJournal {
  @include use-spacings(margin-bottom, 56px);
}

.sectionAbout {
  @include use-spacings(margin-bottom, 56px);
  @include use-spacings(padding-bottom, xl);

  @media (min-width: $tablet-size) {
    @include flex-columns();
  }
}

.image {
  img {
    max-width: 100%;
  }

  @media (min-width: $extra-mobile-size) {
    max-width: 166px;
    height: 92px;

    img {
      max-width: initial;
    }
  }
}
