@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.h2 {
  @include use-spacings(margin-bottom, m);
}

.step {
  @include use-spacings(padding-right, m);
}

.hint {
  color: color(black50);
}

.content {
  display: flex;
}

.add-owner {
  cursor: pointer;

  @include link-style();
}

.controls {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;

  .skip {
    color: color(black50);

    @include use-spacings(margin-top, m);
    @include h5-text();
  }
}

.address {
  @include use-spacings(margin-bottom, 64px);
  @include use-spacings(margin-top, m);
}

.indent-l {
  @include use-spacings(margin-bottom, l);
}

.indent-m {
  @include use-spacings(margin-bottom, m);
}

.indent-s {
  @include use-spacings(margin-bottom, s);
}

.indent-xs {
  @include use-spacings(margin-bottom, xs);
}

@media (min-width: $mobile-size) {
  .controls {
    flex-direction: row;

    .skip {
      @include use-spacings(margin-top, 0);
    }
  }
}
