@import '../../../../../../../../styles/mixins';
@import '../../../../../../../../styles/functions';

.text {
  @include h5-text();
}

.back-button {
  width: 100% !important;
  color: color(mainblack);
  background-color: color(mainwhite);
  border: 1px solid color(mainblack);
  border-radius: 8px;

  @include use-spacings(margin-bottom, s);
  @include flex(center);

  @media (hover) {
    &:hover {
      color: color(mainblack);
      background-color: darken(color(mainwhite), 5);
    }
  }

  @media screen and (min-width: $mobile-size) {
    @include use-spacings(margin-bottom, 0);
  }
}

.input {
  @media screen and (min-width: $mobile-size) {
    width: 328px;
  }
}

.title {
  @include use-spacings(margin-bottom, s);
}

.indent-xs {
  @include use-spacings(margin-bottom, xs);
}

.link {
  cursor: pointer;

  @include link-style();
}

.indent-s {
  @include use-spacings(margin-bottom, s);
}

.link-btn {
  cursor: pointer;

  @include link-style();

  &.disabled {
    color: color(disabled);
    cursor: text;
  }
}

.bottom {
  display: flex;
  flex-direction: column;

  @include use-spacings(margin-top, xs);

  span {
    width: fit-content;

    @include use-spacings(margin-bottom, m);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.error {
  color: color(error);
}
