@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/functions';

.root {
  @include use-spacings(margin-top, l);
}

.extra-hint {
  text-align: center;

  @include use-spacings(margin, m, 0);
}

.controls {
  display: flex;
  flex-direction: column;
}

.submit-button {
  @include flex(center);
  @include use-spacings(margin-top, l);
}

.privacy {
  color: color(black50);
  text-align: center;

  @include small-text();
  @include use-spacings(margin-top, l);
}

.extra {
  color: color(black50);

  @include small-text();
  @include use-spacings(margin-top, s);
}

@media (min-width: $mobile-size) {
  .controls {
    @include flex-columns($flex-direction: row-reverse);
  }

  .privacy {
    padding-top: 10px;
    text-align: left;
  }
}
