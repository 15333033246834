@import '../../../../../../styles/functions';
@import '../../../../../../styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: color(main);
}

.value {
  text-align: center;

  @include h5-text();
  @include use-spacings(margin-top, xs);
  @include use-spacings(padding-left, xs);
}

@media (min-width: $extra-mobile-size) {
  .root {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .value {
    display: flex;
    flex: 0 0;
    min-width: 80%;

    &.no-icon {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: $tablet-size) {
  .value {
    min-width: 70%;

    &.no-icon {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
