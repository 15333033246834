@import 'src/styles/mixins';

.content {
  @include small-light-text();
  @include use-spacings(margin, m, 0);

  @media (min-width: $mobile-size) {
    @include flex-columns();
  }
}

.links {
  @include use-spacings(margin, m, 0);

  @media (min-width: $mobile-size) {
    @include flex-columns();
  }
}

.link {
  display: block;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    @include use-spacings(margin-top, m);
  }
}
