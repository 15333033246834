@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include use-spacings(margin-top, xl);
  @include use-spacings(margin-bottom, xl);
}

.summary {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @include use-spacings(margin-top, m);
}

.see-all {
  @include use-spacings(margin-top, m);

  margin-top: auto;
}

.video {
  overflow: hidden;
  border-radius: $base-border-radius;

  @include use-spacings(margin-top, l);
}

@media (min-width: $tablet-size) {
  .wrapper {
    @include flex-columns();
  }

  .video {
    margin-top: 0;
  }
}
