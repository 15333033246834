.btn {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  margin-top: 16px;
}

.iconRoot {
  align-self: flex-start !important;
  padding: 3px 8px !important;
}
