@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapper {
  @include use-spacings(margin-top, xl);
  @include use-spacings(margin-bottom, xl);
}

.title {
  width: 100%;
}

.content {
  width: 100%;

  @include use-spacings(margin-top, m);
}

.summary {
  width: 100%;
}

.summary-subtitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.summary-content {
  @include use-spacings(margin-top, m);
}

.links {
  @include use-spacings(margin-top, l);
}

.link {
  @include flex();

  flex-wrap: wrap;
  gap: 32px;

  &:not(:first-child) {
    margin-top: 26px;
  }

  @media (min-width: $extra-mobile-size) {
    flex-wrap: nowrap;
  }
}

.img {
  max-width: 100px;

  @include use-spacings(margin-right, m);
}

.journal-point-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

@media (min-width: $mobile-size) {
  .title {
    width: 50%;
  }

  .content {
    @include flex-columns();
  }

  .links {
    margin-top: 0;
  }

  .link {
    align-items: flex-start;
  }
}
