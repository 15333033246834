@import '../../../../styles/functions';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.wrapper {
  @include use-spacings(padding, 'xl', 0);
}

.logo {
  max-width: 220px;
}

.service:not(:last-child) {
  @include use-spacings(margin-bottom, 'l');
}

.service-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;

  @include use-spacings(margin-top, 's');
  @include light-text();
}

@media (min-width: $tablet-size) {
  .wrapper {
    @include flex(space-between, flex-start);
  }

  .service:not(:last-child) {
    margin: 0 $column-gap 0 0;
  }
}
