@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.outerWrap {
  position: relative;
  overflow: hidden;
  // background: color('blueDark');
  // background-image: url(/images/MainMaskMobile.png);
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;

  @include use-spacings(padding-top, 40px);
  @include use-spacings(padding-bottom, xl);

  @media (min-width: $mobile-size) {
    // background-image: url(/images/MainMaskTablet.png);

    @include use-spacings(padding-top, xl);
    @include use-spacings(padding-bottom, l);
  }

  // @media (min-width: $tablet-size) {
  // background-image: url(/images/MainMaskDesktop.png);
  // }

  @media (min-width: $desktop-size) {
    // background-image: url(/images/MainMaskLargeDesktop.png);

    @include use-spacings(padding-bottom, xl);
  }
}

.wrapImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.innerWrap {
  display: grid;
  grid-template-areas:
    "img"
    "title"
    "description"
    "btn";
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  gap: 0 0;
  color: color(mainwhite);

  @media (min-width: $mobile-size) {
    grid-template-areas:
      "title title"
      "description img"
      "btn img";
    grid-template-columns: 390px auto;
    gap: 0 24px;
  }
}

.imageWrap {
  display: flex;
  flex-direction: column;
  grid-area: img;
  align-items: center;
  justify-content: center;

  @include use-spacings(margin-bottom, m);

  @media (min-width: $mobile-size) {
    @include use-spacings(margin-bottom, 0);
  }

  @media (min-width: $tablet-size) {
    align-items: flex-end;
  }
}

.title {
  grid-area: title;

  @include use-spacings(margin-bottom, l);

  @media (min-width: $tablet-size) {
    max-width: 740px;
  }
}

.descriptionWrap {
  display: flex;
  flex-direction: column;
  grid-area: description;
  gap: 16px;

  @include use-spacings(margin-bottom, l);
  @include h5-text();

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-bottom, 0);
  }
}

.button {
  grid-area: btn;
  height: 80px;
  font-weight: 700;

  @media (min-width: $mobile-size) {
    max-width: 345px;
  }

  @media (min-width: $desktop-size) {
    align-self: end;
    max-width: 384px;
  }
}

.logo {
  align-self: flex-start;
  max-width: 140px;
  max-height: 40px;

  @include use-spacings(margin-bottom, m);

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: $extra-mobile-size) {
    max-width: 190px;
    max-height: 48px;
  }

  @media (min-width: $mobile-size) {
    display: none;
  }
}
