@import '../../../../../styles/mixins';
@import '../../../../../styles/functions';

.submit-button-wrap {
  @include use-spacings(margin-top, l);
}

.privacy {
  color: color(black50);
  text-align: center;

  @include small-text();
  @include use-spacings(margin-top, l);
}

.button {
  width: 100%;
}

.submitted-button {
  pointer-events: none;
  background: color(greenbright);

  @include small-text();
}
